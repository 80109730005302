import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useStaticQuery, graphql } from "gatsby";

import Container from "../components/Container";
import weatherIconNames from "../images/weather/weatherIcons.json"; // https://gist.github.com/tbranyen/62d974681dea8ee0caa1
import balvairdEstatesStripes from "../images/balvaird-estates-stripes.svg";
import balvairdEstatesLogo from "../images/balvaird-estates-logo.svg";
import balvairdArms from "../images/balvaird-arms.png";

const Footer = () => {
  const [currentWeather, setCurrentWeather] = useState(null);

  useEffect(() => {
    // get data from OpenWeatherMap API
    fetch(
      `https://api.openweathermap.org/data/2.5/weather?id=4973781&appid=6d0082ded3bd5bb28400051f93d321fd`
    )
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        resultData.main !== undefined ? setCurrentWeather(resultData) : null;
      });
  }, []);

  const currentDate = new Date();

  // Define weather variables
  let currentKelvin: number;
  let currentFarenheit: number;
  let currentCelcius: number;
  let currentWeatherIcon: any;

  // Grabs an icon from the /weather folder based on weather or time.
  const getCurrentIcon = (iconFilename: string) => {
    let iconURL;

    // Append "wi-" prefix
    iconFilename = `wi-${iconFilename}`;

    // Find the Icon within Gatsby-Image
    useStaticQuery(graphql`
      query WeatherIcons {
        icons: allFile(filter: { relativeDirectory: { eq: "weather" } }) {
          nodes {
            name
            publicURL
          }
        }
      }
    `).icons.nodes.forEach(icon => {
      if (icon.name === iconFilename) {
        iconURL = icon.publicURL;
      }
    });
    return iconURL;
  };

  // Determine if current weather data was received
  if (currentWeather) {
    currentKelvin = currentWeather.main.temp;
    currentFarenheit = parseInt((((currentKelvin - 273.15) * 9) / 5 + 32).toFixed(1));
    currentCelcius = parseInt((currentKelvin - 273.15).toFixed(1));

    currentWeatherIcon = () => {
      const weatherCode = currentWeather.weather[0].id;
      let iconFilename = weatherIconNames[weatherCode].icon;

      // If we are not in the ranges mentioned above, add a day/night prefix.
      if (!(weatherCode > 699 && weatherCode < 800) && !(weatherCode > 899 && weatherCode < 1000)) {
        iconFilename = `day-${iconFilename}`;
      }

      return getCurrentIcon(iconFilename);
    };
  }

  const timeIcon = () => {
    return getCurrentIcon("alien");
  };

  return (
    <>
      <Frame>
        <Container>
          <NavLists>
            <NavList>
              <NavListTitle>Oak Hall</NavListTitle>
              <NavLinkInternal to="/">Welcome</NavLinkInternal>
              <NavLinkInternal to="/estate">Estate</NavLinkInternal>
              <NavLinkInternal to="/grounds">Grounds</NavLinkInternal>
              <NavLinkInternal to="/history">History</NavLinkInternal>
              <NavLinkInternal to="/library">Library</NavLinkInternal>
              <NavLinkInternal to="/northport">Northport</NavLinkInternal>
            </NavList>
            <NavList>
              <NavListTitle>Further Reading</NavListTitle>
              <NavLinkExternal
                href="https://npgallery.nps.gov/AssetDetail/NRIS/83003684"
                target="_blank"
              >
                National Registry of Historic Places
              </NavLinkExternal>
              <NavLinkExternal
                href="https://ugapress.org/book/9780820350660/warren-h-manning/"
                target="_blank"
              >
                Warren H. Manning
              </NavLinkExternal>
              <NavLinkExternal href="http://www.northportmaine.org/" target="_blank">
                Town of Northport
              </NavLinkExternal>
              <NavLinkExternal href="https://baysidemaine.com/" target="_blank">
                Bayside Village
              </NavLinkExternal>
              <NavLinkExternal href="http://www.baysidehps.org/" target="_blank">
                Bayside Historical Preservation Society
              </NavLinkExternal>
            </NavList>
            <NavList>
              <NavListTitle>Inquiries</NavListTitle>
              <NavLinkExternal href="mailto:majordomo@oakhallestate.org">
                majordomo@oakhallestate.org
              </NavLinkExternal>
              <NavLinkExternal
                href="https://www.google.com/maps?hl=en&q=459+Bluff+Rd,+Northport,+ME+04849"
                target="_blank"
              >
                459 Bluff Rd
                <br />
                Northport, ME, 04849
              </NavLinkExternal>
              <NavNote>Oak Hall is a private residence and is only open by appointment.</NavNote>
            </NavList>
            <NavList>
              <TimeAndWeather>
                <TimeAndWeatherIcon
                  src={currentWeather ? currentWeatherIcon() : timeIcon()}
                  alt=""
                />
                {currentWeather && (
                  <div>
                    <Temperature>{currentFarenheit}°F</Temperature>
                    <FinePrint>{currentCelcius}°C</FinePrint>
                  </div>
                )}
                <div>
                  <Time>
                    {
                      currentDate.toLocaleString("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                        timeZone: "America/New_York",
                      }) // 04:15 pm
                    }
                  </Time>
                  <FinePrint>
                    {
                      currentDate
                        .toLocaleString("en-US", {
                          timeZone: "America/New_York",
                          timeZoneName: "short",
                        })
                        .split(" ")[3] // EST || EDT
                    }
                  </FinePrint>
                </div>
              </TimeAndWeather>
            </NavList>
          </NavLists>
        </Container>
      </Frame>
      <Frame>
        <BalvairdContainer>
          <BalvairdStripes src={balvairdEstatesStripes} alt="Balvaird Color Stripes" />
          <BalvairdLogo
            src={balvairdEstatesLogo}
            alt="Balvaird Estates Logo"
            title="Balvaird Estates"
          />
          <Notice>
            <FinePrint>
              © 2020-
              {currentDate.getFullYear()} &middot;{" "}
              <NavLinkInternal to="/credits">Credits</NavLinkInternal>
            </FinePrint>
            <a href="https://balvairdestates.com" target="_blank">
              <BalvairdArms src={balvairdArms} alt="Balvaird Arms" />
            </a>
          </Notice>
        </BalvairdContainer>
      </Frame>
    </>
  );
};

const Frame = styled.footer`
  color: #fff;
  background-color: #111;
  margin-top: calc(var(--section-spacing) * 2);
  padding-top: var(--section-spacing);
  padding-bottom: var(--section-spacing);

  &:last-child {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  @media (max-height: 768px) {
    margin-top: var(--section-spacing);
  }
`;

const FinePrint = styled.span`
  font-family: "Calibre";
  text-transform: uppercase;
  font-size: 0.8rem;
  letter-spacing: 0.15em;
  opacity: 0.5;
`;

const NavLists = styled.div`
  display: grid;
  grid-gap: 7vw;
  grid-template-columns: max-content max-content max-content auto;

  @media (max-width: 960px) {
    grid-template-columns: repeat(2, 1fr);

    > *:nth-child(even) {
      text-align: right;
    }
  }

  @media (max-width: 512px) {
    grid-gap: 5vh;

    > *:nth-child(1),
    > *:nth-child(2) {
      grid-column: span 2;
    }

    > *:nth-child(even) {
      text-align: inherit;
    }
  }
`;

const NavList = styled.div`
  display: grid;
  grid-gap: 0.75rem;
  font-size: 0.9rem;
  height: max-content;

  &:last-child {
    text-align: right;
  }
`;

const NavListTitle = styled(FinePrint)`
  margin-bottom: 1.5rem;
`;

const NavLinkInternal = styled(Link)`
  text-decoration: none;
  color: #fff;

  &:hover {
    opacity: 0.5;
  }
`;

const NavLinkExternal = styled.a`
  text-decoration: none;
  color: #fff;

  &:hover {
    opacity: 0.5;
  }
`;

const NavNote = styled.p`
  font-size: 0.8rem;
  font-style: italic;
  max-width: 23ch;
  opacity: 0.5;
`;

const TimeAndWeather = styled.div`
  display: grid;
  grid-gap: 1.5rem;
`;

const TimeAndWeatherIcon = styled.img`
  height: 32px;
  margin-left: auto;
  opacity: 0.8;
`;

const Time = styled.time`
  text-transform: lowercase;
  display: block;
`;

const Temperature = styled.span`
  display: block;
`;

const BalvairdContainer = styled(Container)`
  position: relative;
  display: grid;
  grid-auto-flow: column;
  padding-top: 40px;
  padding-bottom: 32px;
`;

const BalvairdStripes = styled.img`
  position: absolute;
  width: 150px;
  top: 0;
  left: var(--viewport-spacing);
`;

const BalvairdLogo = styled.img`
  height: 40px;
`;

const BalvairdArms = styled.img`
  display: block;
  height: 40px;
  filter: grayscale(1);
  transition: filter 0.5s;

  &:hover {
    filter: grayscale(0);
  }
`;

const Notice = styled.div`
  text-align: right;
  display: grid;
  grid-gap: 1rem;
  grid-auto-flow: column;
  width: max-content;
  margin-left: auto;
  align-items: center;
`;

export default Footer;
