import React from "react";
import styled from "styled-components";

const Container = ({ className = "", children }) => {
  return <Frame className={className}>{children}</Frame>;
};

const Frame = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 var(--viewport-spacing);
  box-sizing: border-box;
`;

export default Container;
