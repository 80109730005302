import React, { useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import { Link } from "gatsby";

import coverLogo from "../images/cover-logo.svg";
import sectionLogo from "../images/section-logo.svg";

import navMenuIcon from "../images/icons/menu.svg";
import navCloseIcon from "../images/icons/close.svg";

const Nav = ({ isCover = false }) => {
  const [navToggle, setNavToggle] = useState(false);

  return (
    <ThemeProvider theme={{ isCover, isActive: navToggle }}>
      <Frame>
        <NavLink to="/estate" onClick={() => setNavToggle(false)}>
          Estate
        </NavLink>
        <NavDivier />
        <NavLink to="/grounds" onClick={() => setNavToggle(false)}>
          Grounds
        </NavLink>
        <NavDivier />
        <NavLogo>
          <LinkLogo to="/" onClick={() => setNavToggle(false)}>
            <Logo src={isCover ? coverLogo : sectionLogo} />
          </LinkLogo>
          <NavToggle
            onClick={() => setNavToggle(!navToggle)}
            src={navToggle ? navCloseIcon : navMenuIcon}
            alt={navToggle ? "Close Menu" : "Open Menu"}
          />
        </NavLogo>
        <NavDivier />
        <NavLink to="/history" onClick={() => setNavToggle(false)}>
          History
        </NavLink>
        <NavDivier />
        <NavLink to="/library" onClick={() => setNavToggle(false)}>
          Library
        </NavLink>
      </Frame>
    </ThemeProvider>
  );
};

const Frame = styled.nav`
  position: relative;
  z-index: 2;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 3rem;
  width: max-content;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  text-align: center;
  padding-top: var(--section-spacing);
  padding-bottom: var(--section-spacing);

  @media (min-width: 960px) {
    /* Index: Inverting the colors so <Nav> it has enough contrast against the background photo */
    ${(props) => props.theme.isCover && `filter: invert(1) brightness(150%);`}
  }

  @media (max-width: 1200px) {
    grid-gap: 1.5rem;
  }

  @media (max-width: 960px) {
    left: 0;
    top: 0;
    grid-auto-flow: row;
    grid-gap: 1rem;
    width: 100%;
    padding-top: calc(var(--section-spacing) / 1.5);

    ${(props) =>
      (!props.theme.isCover || props.theme.isActive) &&
      `
        background-image: linear-gradient(
          var(--cream-bg) 35%,
          rgba(255, 255, 255, 0) 100%
        );
      `}

    filter: ${(props) =>
      !props.theme.isCover || (props.theme.isCover && props.theme.isActive)
        ? "invert(0)"
        : "invert(1) brightness(150%)"};

    padding-bottom: ${(props) =>
      props.theme.isActive
        ? `calc(var(--section-spacing) * 10)`
        : `calc(var(--section-spacing) / 1.5)`};

    position: ${(props) => (props.theme.isActive ? `fixed` : `sticky`)};
  }
`;

const NavLink = styled(Link)`
  font-family: "Calibre", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 0.8rem;
  letter-spacing: 0.5em;
  padding-top: 16px;
  padding-bottom: 16px;
  min-width: 104px;
  color: rgba(0, 0, 0, 0.75);

  &:hover {
    color: rgba(0, 0, 0, 0.9);
  }
  @media (max-width: 960px) {
    display: ${(props) => (props.theme.isActive ? `block` : `none`)};
    font-size: 0.9rem;
    color: rgba(0, 0, 0, 0.75);
  }
`;

const NavDivier = styled.span`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);

  @media (max-width: 960px) {
    display: ${(props) => (props.theme.isActive ? `block` : `none`)};
    margin: 0 auto;
    background-color: rgba(0, 0, 0, 0.15);
  }
`;

const NavLogo = styled.div`
  position: relative;

  @media (max-width: 960px) {
    margin-left: 64px;
    margin-right: 64px;
    margin-bottom: ${(props) =>
      props.theme.isActive ? `var(--section-spacing)` : `none`};
    grid-row: 1;

    /* Hides the <NavDivider> sibling on mobile */
    + span {
      display: none;
    }
  }
`;

const LinkLogo = styled(Link)``;

const Logo = styled.img`
  ${(props) =>
    props.theme.isCover
      ? "width: 192px; height: auto;"
      : "width: auto; height: 80px;"}

  @media (max-width: 960px) {
    ${(props) =>
      props.theme.isCover
        ? "width: 152px; height: auto;"
        : "width: auto; height: 56px;"}
  }
`;

const NavToggle = styled.img`
  @media (min-width: 960px) {
    display: none;
  }

  position: absolute;
  cursor: pointer;
  top: 50%;
  right: 0;
  width: 32px;
  height: 32px;
  border: none;
  outline: none;
  background-color: none;
  transition: transform 100ms;
  transform: translate(100%, -75%);
  opacity: 0.5;

  &:active {
    transform: translate(100%, -75%) scale(0.9);
  }
`;

export default Nav;
