import React from "react";
import styled, { css } from "styled-components";

const Article = ({ className = "", children, isNarrow = false, isCentered = false }) => {
  return (
    <Frame className={className} isNarrow={isNarrow} isCentered={isCentered}>
      {children}
    </Frame>
  );
};

const Frame = styled.article<{ isNarrow: boolean; isCentered: boolean }>`
  position: relative;
  width: clamp(45ch, 50%, 75ch);
  margin-left: auto;
  margin-right: auto;

  ${props =>
    props.isCentered &&
    css`
      text-align: center;
    `}

  > * {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: 512px) {
    width: 100%;
  }
`;

export default Article;
