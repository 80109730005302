import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import "./layout.css";

import layoutBackgroundImage from "../images/background-flourish.svg";
import Footer from "../components/Footer";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <Frame>
      {children}
      <Footer />
    </Frame>
  );
};

const Frame = styled.div`
  background-image: url(${layoutBackgroundImage});
  background-size: 100%;
  background-repeat: repeat-y;
`;

export default Layout;
