import React from "react";
import styled from "styled-components";

const Paragraph = ({ isLarge = false, children }) => {
  return <Frame large={isLarge}>{children}</Frame>;
};

const Frame = styled.p<{ large?: boolean }>`
  line-height: 1.5em;
  font-size: ${props => (props.large ? "1.25rem" : "1rem")};
`;

export default Paragraph;
