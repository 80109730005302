import React from "react";
import styled from "styled-components";

import Container from "../components/Container";

const Section = ({ className = "", children }) => {
  return (
    <Frame className={className}>
      <Container>{children}</Container>
    </Frame>
  );
};

const Frame = styled.section`
  margin: var(--section-spacing) 0;
  clear: both;
`;

export default Section;
