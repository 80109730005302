import React from "react";
import styled from "styled-components";

import TitleDivider from "../components/TitleDivider";

const H1 = ({ children }) => {
  return (
    <Frame>
      {children}
      <TitleDivider />
    </Frame>
  );
};

const Frame = styled.h1`
  display: grid;
  grid-gap: 1rem;
  font-size: 2.5rem;
  font-weight: 700;
  letter-spacing: -0.025em;
  line-height: 1.15em;
`;

export default H1;
