import React from "react";
import styled from "styled-components";

const CardDivider = () => {
  return (
    <Frame>
      <Divider />
    </Frame>
  );
};

const Frame = styled.div`
  position: relative;
  display: block;
  width: 100%;
  font-size: 0;
  line-height: 0;
`;

const Divider = styled.div`
  position: relative;
  display: inline-block;
  height: 4px;
  width: 32px;
  background-color: #e8d9a4;
  line-height: 4px;
`;

export default CardDivider;
